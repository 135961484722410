<template>
  <div id="print" class="user-list">
    <vm-table
      ref="vmTable"
      :filter.sync="filter"
      :pagination-page-size="20"
      url="maintenance/repair-orders/arrivalTime-order/page">
      <el-button
        slot="btn"
        :disabled="status==='loading'"
        size="mini"
        style="height: 26px;padding: 0 10px;margin-left: 10px"
        type="primary"
        @click="exportExcelData">
        {{$l("common.search", "导出")}}
      </el-button>
      <el-button
        slot="btn"
        size="mini"
        style="height: 26px;padding: 0 10px"
        type="primary"
        @click="more=!more">
        {{$l("common.search", "更多")}}
      </el-button>
      <template slot="adSearch">
        <div class="vm-search">
          <vm-search label="楼盘名称">
            <el-input v-model.trim="filter.realEstateName" clearable></el-input>
          </vm-search>
          <vm-search label="班组名称">
            <el-input v-model="filter.teamName" type="iotDeviceModel"></el-input>
          </vm-search>
          <vm-search v-if="more" label="到达时间">
            <vm-dict-select v-model="filter.arrivalTime" type="arrivalType"></vm-dict-select>
          </vm-search>
          <vm-search label="是否困人">
            <el-select v-model="filter.peopleTrapped">
              <el-option :value="true" label="是"></el-option>
              <el-option :value="false" label="否"></el-option>
            </el-select>
          </vm-search>
          <vm-search label="是否完成">
            <el-select v-model="filter.completed">
              <el-option :value="true" label="是"></el-option>
              <el-option :value="false" label="否"></el-option>
            </el-select>
          </vm-search>
        </div>
      </template>

      <el-table-column align="center" type="index" width="50"></el-table-column>
      <el-table-column prop="orderCode" label="工单编号" align="center">
        <template slot="header" slot-scope="">
          工单编号 <el-button type="text" icon="el-icon-sort" @click="sort('orderCode')"></el-button>
        </template>
        <template slot-scope="scope">
          <el-button type="text" @click="$refs.repairDetailNew.open(scope.row)">{{scope.row.orderCode}}</el-button>
        </template>
      </el-table-column>
      <el-table-column prop="teamName" label="班组名称" align="center">
        <template slot="header" slot-scope="">
          班组名称 <el-button type="text" icon="el-icon-sort" @click="sort('teamName')"></el-button>
        </template>
        <template slot-scope="scope">
          {{scope.row.teamName}}
        </template>
      </el-table-column>
      <el-table-column prop="realEstateName" label="楼盘名称" align="center">
        <template slot="header" slot-scope="">
          楼盘名称 <el-button type="text" icon="el-icon-sort" @click="sort('realEstateName')"></el-button>
        </template>
        <template slot-scope="scope">
          {{scope.row.realEstateName}}
        </template>
      </el-table-column>
      <el-table-column prop="buildingName" label="楼宇名称" align="center">
      </el-table-column>
      <el-table-column prop="completed" label="是否完成" align="center">
        <template slot-scope="scope">
          {{scope.row.completed?'是':'否'}}
        </template>
      </el-table-column>
      <el-table-column prop="peopleTrapped" label="是否困人" align="center">
        <template slot-scope="scope">
          {{scope.row.peopleTrapped?'是':'否'}}
        </template>
      </el-table-column>

      <el-table-column prop="arrivalTime" label="救援时间（分钟）" align="center">
        <template slot="header" slot-scope="">
          应急到场时间（分钟） <el-button type="text" icon="el-icon-sort" @click="sort('arrivalTime')"></el-button>
        </template>
        <template slot-scope="scope">
          {{scope.row.arrivalTime}}
        </template>
      </el-table-column>



      <!--      <el-table-column prop="useTypeDesc" label="区分" align="center"></el-table-column>-->
    </vm-table>
    <repair-detail-new ref="repairDetailNew" @save-success="getList(-1)"></repair-detail-new>
  </div>
</template>
<script>

  import {export2Excel, getWidth} from "@/util";
  import print from  "print-js";
  import moment from "moment";
  import loginUtil from "@/util/loginUtil";
  import RepairDetailNew from  "@/views/maintenance/repair/reportOrder/RepairDetailNew";
  export default {
    components:{RepairDetailNew},
    data() {
      return {
        loading:false,
        filter: {
          name: "",
          maintenanceType:"as_need",
          realEstateName:"",
          companyId: loginUtil.getCompanyCode(),
          createTimeFrom:"2023-01-01 00:00:00",
          // arrivalTime:"five_ten",
          arr:[{
            orderColumnName:"arrivalTime",
            asc:0,
          }],
          orderColumnNames:"arrivalTime|0",
        },
        senior: false,
        data:[],
        width:200,
        visible:false,
        more:false,
      };
    },
    mounted() {
      this.getList(1);
      this.getTreeList();
    },
    methods: {
      exportExcelData() {
        const title={
          realEstateName:"楼盘",
          buildingName:"楼宇",
          teamName:"班组名称",
          arrivedTime:"到达时间",
          arrivalTime:"救援时间（分钟）",

        };
        this.$http.get("maintenance/repair-orders/arrivalTime-order/page",{...this.filter,pageSize:300000}).then(res=>{

          export2Excel(title,res.records,`数据统计-救援时间-${res.total}条-${moment(new Date()).format("YYYYMMDDhhmmss")}`);
        });
      },
      sort(name) {
        if (this.filter.arr.some(item=>item.orderColumnName===name)) {
          const arr = this.filter.arr.map(item=>{
            console.log(item.orderColumnName,name);
            if (item.orderColumnName===name) {
              item.asc=item.asc?0:1;
            }
            return item;
          });
          this.filter.arr=arr;
        }else {
          this.filter.arr.push({
            orderColumnName:name,
            asc:0,
          });
        }
        let str = "";
        this.filter.arr.forEach(item=>{
          str+=item.orderColumnName+"|"+item.asc+"&";
        });
        this.filter.orderColumnNames = str;
        this.getList();
      },
      printSomething() {
        print({
          printable:"print",
          type:"html",
        });
      },
      getList(pageNum) {
        this.$refs.vmTable.getList(pageNum);
        this.visible=false;
      },
      deleteRow(row) {
        this.$confirm(this.$l("common.deleteTip", "确定删除{key}吗？", {key: row.name}), this.$l("common.tip", "提示"), {type: "warning"}).then(() => {
          this.$http.delete(`/elevator-archive/${row.id}`).then(() => {
            this.getList(-1);
            this.$message.success(row.name + this.$l("common.deleteSuccessTip", "删除成功"));
          });
        });
      },
      gotoMonitor(elevatorId) {
        let routeUrl = this.$router.resolve({
          path: "/monitor/LiftMonitor",
          query: {elevatorId},
        });
        window.open(routeUrl.href, "_blank");
      },
      goToDetail({realEstateName}) {
        this.$router.push({path:"/datastatistics/elevatoroffline",query:{realEstateName}});
      },
      handleSeniorClick() {
        this.senior=!this.senior;
      },
      getTreeList() {
        let filter = this.filter;
        if (this.filterFun) {
          let filterCopy = JSON.parse(JSON.stringify(filter));
          filter = this.filterFun(filterCopy);
        }
        let http =  this.$http.get("/basic/district/tree/locate-and-with-real-estate");
        http.then(data => {
          this.data = data;
          this.width=100+14*getWidth(data);
        }).catch(() => {
          this.status = "error";
        });
      },
      handleNodeClick(e) {

        if (e.other!=="district") {
          this.filter.realEstateName=e.label;
          this.getList(-1);
        }

      },

    },
  };
</script>
<style lang="scss" scoped>
.treeBox{
  border: 1px solid #E6E6E6;
  padding: 10px;
  margin-right: 10px;
  width: calc(100% - 1300px);
  max-height: 1080px;
  overflow-y: auto;
}
</style>
